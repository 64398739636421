@import '../../../scss/theme-bootstrap';

.vertical-carousel-navigation-v1 {
  .dots {
    position: fixed;
    top: 60%;
    #{$rdirection}: 0;
    width: 45px;
    border-#{$rdirection}: 0;
    z-index: 999999;
    .item {
      height: 25px;
      cursor: pointer;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .first {
      background-image: url($template_base_path + '/vertical_carousel_navigation_v1/toc-up.png');
      height: 10px;
    }
    .last {
      background-image: url($template_base_path + '/vertical_carousel_navigation_v1/toc-down.png');
    }
    .dot {
      position: relative;
      background-color: rgba(0, 0, 0, 0);
      background-image: none;
      border-radius: 50%;
      height: 8px;
      @include swap_direction(margin, 6px 0 6px 19px);
      -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6) inset;
      -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6) inset;
      -o-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6) inset;
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.6) inset;
      height: 8px;
      position: relative;
      transition: -webkit-box-shadow 0.3s ease 0s;
      transition: -moz-box-shadow 0.3s ease 0s;
      transition: -o-box-shadow 0.3s ease 0s;
      transition: box-shadow 0.3s ease 0s;
      width: 8px;
      .title {
        position: absolute;
        top: -5px;
        #{$rdirection}: 20px;
        display: none;
        text-align: #{$rdirection};
        min-width: 300px;
      }
      &.active,
      &.hover {
        background-color: rgba(0, 0, 0, 0);
        background-image: none;
        -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
        -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
        -o-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
        box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
      }
      &.hover .title {
        display: block;
      }
    }
  }
  .module {
    &.last {
      border-bottom: 0;
    }
  }
}
/* Mobile: 3-Step section only */
.device-mobile.section-3-step-sticky-header-collection .vertical-carousel-navigation-v1 .module {
  border: none;
}
